import { AttributeEntity, AttributeEntityValue } from "@/main/webapp/vue/model/api/AttributeEntity";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { JsonDiscriminator, JsonField } from "@znapio/znapson";

export class DateRepresentableAttributeEntityValue implements AttributeEntityValue {
  constructor(@JsonField('value', String)
              public value: string) {}
}

@JsonDiscriminator(AttributeEntity, 'date')
export class DateAttributeEntity extends AttributeEntity<DateRepresentableAttributeEntityValue> {
  constructor(id: number,
              name: string,
              type: string,
              @JsonField('values', [DateRepresentableAttributeEntityValue])
              public values: DateRepresentableAttributeEntityValue[],
              value?: string,
              unit?: string,
              nav?: NavigationLinks) {
    super(id, name, type, value, unit, nav);
  }
}
