
import Vue from 'vue';
import { mapState } from "vuex";

import fieldSelector from "@/main/webapp/vue/components/ui/table/field-selector/index.vue";
import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";

export default Vue.extend({
  components: {
    fieldSelector
  },
  props: {
    title: {
      type: String
    }
  },
  computed: {
    ...mapState([
      'projectSubmissionFields'
    ])
  },
  methods: {
    updateFields(fields: Field[]) { // this will only active, inactive changes
      this.$store.dispatch('updateProjectSubmissionFields', fields);
    }
  }
});
