
import Vue, { PropType } from 'vue';
import { mapState } from "vuex";
import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

import listView from "@/main/webapp/vue/views/submissions-view/list-view/index.vue";
import notification from "@/main/webapp/vue/notification";

import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
import { SubmissionContainer } from "@/main/webapp/vue/model/api/SubmissionContainer";
import { SubmissionQueryCriteria } from "@/main/webapp/vue/model/SubmissionQueryCriteria";
import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";

export default Vue.extend({
  components: {
    listView
  },
  props: {
    submissionQueries: {
      type: Object as PropType<SubmissionQueryCriteria>,
      default: null
    }
  },
  computed: {
    ...mapState([
      'projectSubmissionFields'
    ]),
    computedSearchCriteria(): SearchCriteria | null {
      if (this.submissionContainer) {
        const searchCriteria = new SearchCriteria();
        searchCriteria.results = this.submissionContainer;
        return searchCriteria;
      }
      return null;
    }
  },
  data() {
    return {
      loading: false as boolean,
      submissionContainer: null as SubmissionContainer | null
    };
  },
  methods: {
    onFieldChange(fields: Field[], dynamicallyAddedFields?: Field[]): void {
      let newFields = [...this.projectSubmissionFields];
      if (dynamicallyAddedFields && dynamicallyAddedFields.length > 0) {
        // check if already added from other submissions
        const newlyAddedFields: Field[] = dynamicallyAddedFields.filter((field: Field) => {
          return !(this.projectSubmissionFields.some((f: Field) => f.key === field.key));
        });

        if (newlyAddedFields.length > 0) {
          newFields.push.apply(newFields, newlyAddedFields);
        }
      }

      this.$store.dispatch('updateProjectSubmissionFields', newFields);
    },
    fetchSubmission(params: SubmissionQueryCriteria): void {
      this.loading = true;
      BackendIntegrationService.fetchSubmissionListWithCriteria(params)
        .then((submissionContainer: SubmissionContainer) => {
          if (submissionContainer) {
            this.submissionContainer = submissionContainer;
          }
        }).catch((e: Error) => {
          console.log('error', e);
          notification.fail(this.$t('text.error-loading'));
        }).finally(() => {
          this.loading = false;
      });
    }
  },
  created(): void {
    this.fetchSubmission(this.submissionQueries);
  },
  i18n: {
    sharedMessages: sharedMessages
  }
});
