import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import sitemapWidget from '@/main/webapp/vue/components/learning/widget/widgets/sitemap/widget/index.vue';
import sitemapWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/sitemap/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import ButtonStyleModel from "@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel";
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import IconStyleModel from "@/main/webapp/vue/components/ui/style/icon/model/IconStyleModel";
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export enum DefaultValues {
  Heading = ''
}

export class SitemapData {
  constructor(@JsonField('heading', String)
              public heading: string = DefaultValues.Heading) {} // dummy value, empty constructor threw an error
}

export class SitemapProperties {
  constructor(@JsonField('iconStyle', IconStyleModel, true)
              public iconStyle = new IconStyleModel("circle", "#FF0F0F", 20, "#00FF00"),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'sitemap')
export default class SitemapWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', SitemapData)
              public data = new SitemapData(),
              @JsonField('properties', SitemapProperties)
              public properties = new SitemapProperties(),
              @JsonField('defaultProperties', SitemapProperties, true)
              public defaultProperties =
                new SitemapProperties(
                  new IconStyleModel("circle", "#FF0F0F", 20, "#00FF00"),
                  new ButtonStyleModel('#17a2b8', '#ffffff', 5)),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.NAVIGATION,
  SitemapWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'menu_open', // 'data_table' broken
  sitemapWidget,
  sitemapWidgetProperties
);
