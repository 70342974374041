
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';

  import SitemapWidgetModel
    from "@/main/webapp/vue/components/learning/widget/widgets/sitemap/model/SitemapWidgetModel";

  export default Vue.extend({
    name: 'SitemapWidgetProperties',
    props: {
      model: {
        type: Object as PropType<SitemapWidgetModel>,
        required: true
      }
    },
    components: {
      textTitle
    },
    i18n: {
      messages: messages
    }
  });
