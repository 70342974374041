import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";
import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";

export class WebSocketTaskDefinitions {

  static generateDefinitionWithTypeString(typeString: String): WebSocketTaskDefinition {
    return this.generateDefinitionWithType(typeString as unknown as WebSocketTaskType);
  }

  static generateDefinitionWithType(type: WebSocketTaskType): WebSocketTaskDefinition {
    switch (type) {
      case WebSocketTaskType.CONTENT_PACKAGE:
        return new WebSocketTaskDefinition(type, "/content-package", "zip", 15000, 200);
      case WebSocketTaskType.CONTENT_REPORT:
        return new WebSocketTaskDefinition(type, "/content-report", "pptx", 15000, 250);
      case WebSocketTaskType.RAW_DATA:
        return new WebSocketTaskDefinition(type, "/raw-data-report", "xlsx", 20000, 30000);
      case WebSocketTaskType.CHECK_IN:
        return new WebSocketTaskDefinition(type, "/check-in-report", "xlsx", 5000);
      case WebSocketTaskType.PROJECT_LIST:
        return new WebSocketTaskDefinition(type, "/project-list-report", "xlsx", 5000);
      case WebSocketTaskType.PROJECT_STATUS:
        return new WebSocketTaskDefinition(type, "/project-status-report", "xlsx", 5000);
      case WebSocketTaskType.PROJECT_USER_PERFORMANCE:
        return new WebSocketTaskDefinition(type, "/project-user-performance-report", "xlsx", 5000);
      case WebSocketTaskType.FRAUD_DATA:
        return new WebSocketTaskDefinition(type, "/fraud-data-report", "xlsx", 5000, 30000);
      case WebSocketTaskType.SHOP_EXPORT:
        return new WebSocketTaskDefinition(type, "/shop-export", "xlsx", 5000);
      case WebSocketTaskType.SHOP_IMPORT:
        return new WebSocketTaskDefinition(type, "/shop-import", "csv", 5000);
      case WebSocketTaskType.DELETE_INACTIVE_SHOPS:
        return new WebSocketTaskDefinition(type, "/delete-inactive-shops", "", 10000);
      case WebSocketTaskType.SHOP_REGISTRY_SYNC:
        return new WebSocketTaskDefinition(type, "/shop-registry-sync", "", 10000);
    }

    throw new Error("Task type not registered");
  }

  static types(): WebSocketTaskType[] {
    return Object.values(WebSocketTaskType);
  }

}
