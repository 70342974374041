import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { Json2TypescriptConverter } from "@znapio/znapson/json2typescriptAdapter";
import { JsonField, JsonSuperclass } from "@znapio/znapson";

export interface AttributeEntityValue {
}

@JsonSuperclass('type')
export abstract class AttributeEntity<T extends AttributeEntityValue> {
  protected constructor(@JsonField('id', Number)
                        public id: number,
                        @JsonField('name', String)
                        public name: string,
                        @JsonField('type', String)
                        public type: string,
                        @JsonField('value', String, true)
                        public value?: string,
                        @JsonField('unit', String, true)
                        public unit?: string,
                        @JsonField('nav', NavigationLinks, true, {
                          customConverter: new Json2TypescriptConverter(NavigationLinks, true)
                        })
                        public nav?: NavigationLinks) { }

  abstract values: T[];
}
