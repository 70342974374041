import WidgetModel, {
  WidgetIdType,
  DraggableSection,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import collapsibleWidget from '@/main/webapp/vue/components/learning/widget/widgets/collapsible/widget/index.vue';
import collapsibleWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/collapsible/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";
import CollapsibleStyleModel
  from "@/main/webapp/vue/components/ui/style/collapsible-button/model/CollapsibleStyleModel";

export enum DefaultValues {
  Heading = 'DefaultHeading'
}

export class CollapsibleWidgetData {
  constructor(@JsonField('heading', String)
              public heading: string = DefaultValues.Heading,
              @JsonField('includeInSitemap', Boolean, true)
              public includeInSitemap: boolean = true) {}
}

export class CollapsibleWidgetProperties {
  constructor(@JsonField('style', CollapsibleStyleModel, true)
              public style = new CollapsibleStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'collapsible')
export default class CollapsibleWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', CollapsibleWidgetData)
              public data = new CollapsibleWidgetData(),
              @JsonField('properties', CollapsibleWidgetProperties)
              public properties = new CollapsibleWidgetProperties(),
              @JsonField('defaultProperties', CollapsibleWidgetProperties, true)
              public defaultProperties = new CollapsibleWidgetProperties(),
              @JsonField('nestedContents', [WidgetModel], true)
              public nestedContents: WidgetModel[] = [],
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }

  updateInternal(newModel: CollapsibleWidgetModel): void {
    if (this.nestedContents && this.nestedContents.length > 0) {
      this.nestedContents = newModel.nestedContents;
    }
  }

}

registerWidget(
  WidgetFamily.LAYOUT,
  CollapsibleWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'unfold_less',
  collapsibleWidget,
  collapsibleWidgetProperties
);
