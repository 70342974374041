
import Vue, { PropType } from 'vue';

import glyphiconAction from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-action/index.vue";
import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";

export default Vue.extend({
  components: {
    glyphiconAction
  },
  props: {
    fields: {
      type: Array as PropType<Field[]>,
      default: null
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      displayFields: [...this.fields] as Field[]
    };
  },
  watch: {
    fields(newValue: Field[], oldValue: Field[]) {
      this.displayFields = newValue;
    }
  },
  methods: {
    updateFields(): void {
      this.$emit('update-fields', this.displayFields);
    },
    togglePopover(show: boolean): void {
      const popover = this.$refs.popover;
      if (popover) {
        popover.$emit(show ? 'open' : 'hide');
      }
    }
  }
});
