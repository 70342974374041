import { JsonField } from '@znapio/znapson';


export default class ActivityProperties {

  constructor(@JsonField('showInActivityList', Boolean)
              public showInActivityList: Boolean = true,
              @JsonField('showLogo', Boolean)
              public showLogo: Boolean = true,
              @JsonField('logoLinkMainActivity', Boolean, true)
              public logoLinkMainActivity: Boolean = true,
              @JsonField('promptAddToHome', Boolean, true)
              public promptAddToHome: Boolean = false,
              @JsonField('reuseHeader', Boolean, true)
              public reuseHeader: Boolean = false,
              @JsonField('reuseFooter', Boolean, true)
              public reuseFooter: Boolean = false) {}
}

