import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import headingWidget from '@/main/webapp/vue/components/learning/widget/widgets/heading/widget/index.vue';
import headingWidgetProperties from '@/main/webapp/vue/components/learning/widget/widgets/heading/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import TextStyleModel, {
  TEXT_ALIGNMENT,
  TEXT_SIZE
} from "@/main/webapp/vue/components/ui/style/text/model/TextStyleModel";
import TextStyleWidgetProperties from "@/main/webapp/vue/model/learning/TextStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";
import { v1 as uuidv1 } from "uuid";

export class HeadingWidgetData {
  constructor(@JsonField('text', String)
              public text: string = '',
              @JsonField('includeInSitemap', Boolean, true)
              public includeInSitemap: boolean = true) {}
}

@JsonDiscriminator(WidgetModel, 'heading')
export default class HeadingWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.ALL,
              metadata = new WidgetMetadata(),
              @JsonField('data', HeadingWidgetData)
              public data = new HeadingWidgetData(),
              @JsonField('properties', TextStyleWidgetProperties)
              public properties = new TextStyleWidgetProperties(),
              @JsonField('defaultProperties', TextStyleWidgetProperties, true)
              public defaultProperties =
                new TextStyleWidgetProperties(
                  new TextStyleModel(TEXT_SIZE.TITLE, '#000000', TEXT_ALIGNMENT.LEFT)),
              allowGlobalDefaultStyle: boolean = false,
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata, allowGlobalDefaultStyle);
  }

}

registerWidget(
  WidgetFamily.BASIC,
  HeadingWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'title',
  headingWidget,
  headingWidgetProperties
);
