
  import Vue from 'vue';
  import messages from './messages.json';

  import courseLogo, { CourseLogoType } from "@/main/webapp/vue/components/learning/ui/content/course-logo/index.vue";
  import widgetComponent from "@/main/webapp/vue/components/learning/widget/component/WidgetComponent.vue";
  import organizationFooter from "@/main/webapp/vue/components/ui/footer/index.vue";

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";

  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import CourseProperties from "@/main/webapp/vue/model/api/learning/CourseProperties";
  import { CourseBranding } from "@/main/webapp/vue/model/api/learning/CourseBranding";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
  import { ActivityPublic } from "@/main/webapp/vue/model/api/learning/ActivityPublic";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { DraggableSection } from "@/main/webapp/vue/model/learning/WidgetModel";
  import { OrganizationData } from "@/main/webapp/vue/model/api/learning/OrganizationData";
  import addToHomescreenUtil from "@/main/webapp/vue/util/addToHomescreenUtil";
  import learningActivityStatusSessionUtil from "@/main/webapp/vue/util/learningActivityStatusSessionUtil";
  import GoogleAnalyticsUtil from "@/main/webapp/vue/util/googleAnalyticsUtil";

  enum ErrorType {
    NOT_FOUND = "not-found",
    INACTIVE = "inactive",
    UNAVAILABLE = "unavailable"
  }

  export default Vue.extend({
    components: {
      courseLogo,
      widgetComponent,
      organizationFooter
    },
    props: {
      courseId: {
        type: Number,
        required: true
      },
      courseActive: {
        type: Boolean,
        required: true
      },
      courseUrlPath: {
        type: String,
        default: ''
      },
      activityId: {
        type: Number,
        required: true
      },
      activityActive: {
        type: Boolean,
        required: true
      }
    },
    data: function() {
      return {
        course: {} as CourseModel,
        activity: {} as Activity,
        activityModel: {} as ActivityModel,
        section: {
          header: DraggableSection.HEADER as DraggableSection,
          body: DraggableSection.BODY as DraggableSection,
          footer: DraggableSection.BODY as DraggableSection
        },
        courseLogo: {
          type: CourseLogoType.LOGO as CourseLogoType
        },
        organizationData: undefined as OrganizationData | undefined,
        error: undefined as ErrorType | undefined,
        googleAnalyticsUtil: null as GoogleAnalyticsUtil | null
      };
    },
    computed: {
      headerWidgets() {
        return (this.activityModel?.widgets ?? []).filter(widget => widget.section === 'header');
      },
      bodyWidgets() {
        return (this.activityModel?.widgets ?? []).filter(widget => widget.section === 'body');
      },
      footerWidgets() {
        return (this.activityModel?.widgets ?? []).filter(widget => widget.section === 'footer');
      }
    },
    methods: {
      showAddToHomescreen(): void {
        if (this.activityActive && this.activityModel.properties && this.activityModel.properties.promptAddToHome) {
          addToHomescreenUtil.show(this.activityId, this.$t('text.add-to-home.error').toString());
        }
      },
      fetchGoogleAnalytics(courseProperties: CourseProperties) {
        if (this.activity && courseProperties && courseProperties.googleAnalytics && courseProperties.googleAnalytics.measurementId) {
          const measurementId: string = courseProperties.googleAnalytics.measurementId;
          // No instance or different instance, then create new
          if (!this.googleAnalyticsUtil || this.googleAnalyticsUtil.measurementId !== measurementId) {
            this.googleAnalyticsUtil = new GoogleAnalyticsUtil(measurementId);
          }

          if (this.googleAnalyticsUtil) {
            this.googleAnalyticsUtil.pageView(this.activityModel);
          }
        }
      },
      markActivityVisited(activityId: number | undefined): void {
        if (activityId) {
          learningActivityStatusSessionUtil.markVisited(activityId);
        }
      },
      fetchData() {
        this.course = new CourseModel();
        this.course.id = this.courseId;
        this.course.urlPath = this.courseUrlPath;
        const course: Course = Course.from(this.course);

        this.activity = new Activity();
        this.activity.id = this.activityId;

        LearningIntegrationService.fetchCourseActivityDetailsOnline(course, this.activity)
          .then((activityPublic: ActivityPublic) => {
            this.activityModel = ActivityPublic.toActivityModel(activityPublic);
            this.course.urlPath = this.activityModel.baseUrl;
            this.organizationData = activityPublic.organizationData;
            if (activityPublic.defaultStyles) {
              this.$store.dispatch('updateCourseDefaultStyles',
                                   CourseBranding.deserializeDefaultStyle(activityPublic.defaultStyles));
            }

            this.fetchGoogleAnalytics(activityPublic.courseProperties);
            this.markActivityVisited(activityPublic.id);
            this.showAddToHomescreen();
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Learning online data error");
              console.log(e);
            }
            this.error = ErrorType.UNAVAILABLE;
          });
      }
    },
    mounted() {
      this.$nextTick(() => {
        const hash = decodeURIComponent(window.location.hash);
        if (hash) {
          setTimeout(() => {
            const anchor = hash.substring(1); // Remove #
            const element = document.querySelector(`[data-anchor="${anchor}"]`);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 300); // Ensure DOM is fully rendered
        }
      });

      if (this.courseId < 0 || this.activityId < 0) {
        this.error = ErrorType.NOT_FOUND;
      } else if (!this.courseActive || !this.activityActive) {
        this.error = ErrorType.INACTIVE;
      } else {
        this.fetchData();
      }
    },
    beforeDestroy(): void {
      if (this.activityActive && this.activityModel.properties && this.activityModel.properties.promptAddToHome) {
        addToHomescreenUtil.updateSession(this.activityId, this.$t('text.add-to-home.error').toString());
      }
    },
    i18n: {
      messages: messages
    }
  });
