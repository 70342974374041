import { JsonField, JsonSuperclass } from '@znapio/znapson';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export type WidgetIdType = string | number;

export const enum DraggableSection {
  ALL = 'all',
  HEADER = 'header',
  BODY = 'body',
  FOOTER = 'footer'
}

export class WidgetMetadata {
  constructor(@JsonField('originalId', String)
              public originalId: WidgetIdType = '',
              @JsonField('humanReadableText', String, true)
              public humanReadableText: WidgetIdType = '') {}
}

@JsonSuperclass('widgetType')
export default abstract class WidgetModel {

  abstract data: any;
  abstract properties: any;
  abstract readonly defaultProperties: any;
  abstract activities: NamedModel[];

  protected constructor(@JsonField('id', Number)
                        public id: WidgetIdType,
                        @JsonField('section', String)
                        public section: DraggableSection,
                        @JsonField('metadata', WidgetMetadata)
                        public metadata: WidgetMetadata,
                        @JsonField('allowGlobalDefaultStyle', Boolean)
                        public allowGlobalDefaultStyle: Boolean = true) {
    if (metadata.originalId === '') {
      metadata.originalId = id;
    }
  }

  public updateId(id: number): void {
    this.metadata.originalId = this.id;
    this.id = id;
  }

  public isPersisted(): boolean {
    return this.id !== undefined && this.metadata.originalId !== undefined && this.id !== this.metadata.originalId;
  }

  public updateWithModel(newModel: WidgetModel): void {
    this.id = newModel.id;
    this.metadata = newModel.metadata;
    this.activities = newModel.activities;

    this.updateInternal(newModel);
  }

  public updateInternal(newModel: WidgetModel): void {
    // To be overridden by classes that need to perform more operations on update
  }

}
