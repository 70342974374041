
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";

  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import ActivityModel from '@/main/webapp/vue/model/learning/ActivityModel';
  import SitemapWidgetModel from '@/main/webapp/vue/components/learning/widget/widgets/sitemap/model/SitemapWidgetModel';
  import UriUtil from "@/main/webapp/vue/util/uriUtil";

  export default Vue.extend({
    name: 'SitemapWidget',
    props: {
      model: {
        type: Object as PropType<SitemapWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        parentActivity: undefined as ActivityModel | undefined,
        pageReady: true as Boolean
      };
    },
    computed: {
      filteredSubactivities() {
        if (!this.parentActivity || !this.parentActivity.subactivities) {
          return [];
        }

        const filterContents = (contents) => {
          return contents
              .filter(content => content.data.includeInSitemap)
              .map(content => ({
                ...content,
                nestedContents: content.nestedContents ? filterContents(content.nestedContents) : []
              }))
              .filter(content => content.nestedContents.length > 0 || content.data.includeInSitemap);
        };

        return this.parentActivity.subactivities
            .map(subactivity => ({
              ...subactivity,
              filteredContents: filterContents(subactivity.contents || [])
            }))
            .filter(subactivity => subactivity.filteredContents.length > 0);
      }
    },
    methods: {
      getContentHref(subactivity, content) {
        const anchor = this.anchorize(content.metadata.humanReadableText);
        return this.editable ? '#' : `${subactivity.urlPath}#${anchor}`;
      },
      anchorize(text: string) {
        return UriUtil.generateUrlPathFragment(text);
      }
    },
    mounted() {
      const course: Course = Course.from(this.course);
      LearningIntegrationService.fetchCourseSitemapOnline(course.id)
        .then((response: Course) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(response);
          }
          this.parentActivity = response.mainActivity;
        // eslint-disable-next-line
        }).catch((error: BackendError) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Failed to fetch activity details, using fallback instead");
          }
        });
    },
    i18n: {
      messages: messages
    }
  });
