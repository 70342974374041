import { Any, JsonObject, JsonProperty } from "json2typescript";

import WidgetModel from "@/main/webapp/vue/model/learning/WidgetModel";
import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
import Znapson from '@znapio/znapson';

@JsonObject('ContentUnit')
export class ContentUnit {

  @JsonProperty('id', Number, true)
  id?: number = undefined;

  @JsonProperty('widgetType', String, true)
  widgetType?: string = undefined;

  @JsonProperty('metadata', Any, true)
  metadata?: any = undefined;

  @JsonProperty('data', Any, true)
  data?: any = undefined;

  @JsonProperty('properties', Any, true)
  properties?: any = undefined;

  @JsonProperty('sequence', Number, true)
  sequence?: number = undefined;

  @JsonProperty('section', String, true)
  section?: string = undefined;

  @JsonProperty('active', Boolean, true)
  active?: boolean = undefined;

  @JsonProperty('created', String, true)
  created?: string = undefined;

  @JsonProperty('urlPathFragment', String, true)
  urlPathFragment?: string = undefined;

  @JsonProperty('activities', [NamedEntity], true)
  activities?: NamedEntity[] = undefined;

  @JsonProperty('nestedContents', [ContentUnit], true)
  nestedContents?: ContentUnit[] = undefined;

  protected static fromSerializedWidget(serializedWidget: any, index?: number): ContentUnit {
    let contentUnit: ContentUnit = new ContentUnit();
    contentUnit.id = serializedWidget.id;
    contentUnit.widgetType = serializedWidget.widgetType as string;
    contentUnit.metadata = serializedWidget.metadata;
    contentUnit.data = serializedWidget.data;
    contentUnit.properties = serializedWidget.properties;
    contentUnit.activities = serializedWidget.activities;
    if (index) {
      contentUnit.sequence = index;
    }
    contentUnit.section = serializedWidget.section as string;
    if (Array.isArray(serializedWidget.nestedContents)) {
      contentUnit.nestedContents = serializedWidget.nestedContents.map(ContentUnit.fromSerializedWidget);
    }

    return contentUnit;
  }

  static from(widgetModel: WidgetModel, index?: number): ContentUnit {
    return ContentUnit.fromSerializedWidget(Znapson.serialize(widgetModel), index);
  }

}
