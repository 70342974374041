
  import Vue, { PropType } from 'vue';

  import webSocketRequest from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue";

  import { ReportProjectStatus } from "@/main/webapp/vue/model/api/web-socket/ReportProjectStatus";

  export default Vue.extend({
    components: {
      webSocketRequest
    },
    props: {
      type: {
        type: String, // Object as PropType<WebSocketTaskType>,
        required: true
      }
    },
    data() {
      return {
        payload: null as ReportProjectStatus | null
      };
    },
    methods: {
      queryProjectSelectors(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          // @ts-ignore
          const projectId = document.querySelector('#project-select').value;

          // @ts-ignore
          const projectStatuses = Array.from(document.querySelector('#project-status-select').querySelectorAll("option:checked"), e => e.value);

          // @ts-ignore
          const filter = document.querySelector('#projects-shop-table-filter').value;

          // @ts-ignore
          const archived : boolean = this.$route.query.archived;

          this.payload = new ReportProjectStatus(projectId, projectStatuses, filter, archived);
          resolve(this.payload);
        });
      }
    }
  });
