import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import contentEditorWidget from '@/main/webapp/vue/components/learning/widget/widgets/content-editor/widget/index.vue';
import contentEditorWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/content-editor/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class ContentEditorWidgetData {
  constructor(@JsonField('html', String)
              public html: string = '') {}
}

export class ContentEditorWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}


@JsonDiscriminator(WidgetModel, 'contentEditor')
export default class ContentEditorWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.ALL,
              metadata = new WidgetMetadata(),
              @JsonField('data', ContentEditorWidgetData)
              public data = new ContentEditorWidgetData(),
              @JsonField('properties', ContentEditorWidgetProperties)
              public properties = new ContentEditorWidgetProperties(),
              @JsonField('defaultProperties', ContentEditorWidgetProperties, true)
              public defaultProperties = new ContentEditorWidgetProperties(),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.ADVANCED,
  ContentEditorWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'edit_note',
  contentEditorWidget,
  contentEditorWidgetProperties
);
