
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import collapsibleButtonStyle from '@/main/webapp/vue/components/ui/style/collapsible-button/container/index.vue';
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import CollapsibleWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/collapsible/model/CollapsibleWidgetModel';

  export default Vue.extend({
    name: 'CollapsibleWidgetProperties',
    props: {
      model: {
        type: Object as PropType<CollapsibleWidgetModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      collapsibleButtonStyle,
      genericStyle
    },
    watch: {
      'model.data.heading'(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.model.metadata.humanReadableText = newVal;
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
