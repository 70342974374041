
import Vue, { PropType } from 'vue';
import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";

export default Vue.extend({
  props: {
    element: {
      type: Object as PropType<HierarchicalEntity>,
      default: null
    }
  }
});
