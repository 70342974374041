
  import Vue from 'vue';
  import { mapState } from "vuex";
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  import submissionsPage from "@/main/webapp/vue/views/submissions-view/ui/submissions-page/index.vue";
  import submissionsTitle from "@/main/webapp/vue/views/submissions-view/ui/submissions-title/index.vue";
  import submissionsSwitcher, {
    SubmissionsView
  } from "@/main/webapp/vue/views/submissions-view/ui/submissions-switcher/index.vue";
  import submissionsMenus from "@/main/webapp/vue/views/submissions-view/ui/submissions-menus/index.vue";
  import submissionsFilter from "@/main/webapp/vue/components/ui/filter/index.vue";
  import filterCriteriaSelector, { CriterionSelector }
    from "@/main/webapp/vue/components/ui/filter/filter-criteria-selector/index.vue";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { generateShareHTMLService } from "@/main/webapp/vue/services/GenerateShareHTMLService";

  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
  import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";
  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
  import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
  import { ListViewSelectedField } from "@/main/webapp/vue/model/api/ListViewSelectedField";
  import { ShareItem } from "@/main/webapp/vue/model/api/ShareItem";
  import { SearchDefinition } from "@/main/webapp/vue/model/api/SearchDefinition";
  import { SearchData } from "@/main/webapp/vue/model/api/SearchData";

  export default Vue.extend({
    components: {
      submissionsPage,
      submissionsTitle,
      submissionsSwitcher,
      submissionsMenus,
      submissionsFilter,
      filterCriteriaSelector
    },
    props: {
      showSlideContainer: {
        type: Boolean,
        default: false
      },
      showReportDropdown: {
        type: Boolean,
        default: false
      },
      contentPackageEnabled: {
        type: Boolean,
        default: false
      },
      contentReportEnabled: {
        type: Boolean,
        default: false
      },
      rawDataReportEnabled: {
        type: Boolean,
        default: false
      },
      checkInReportEnabled: {
        type: Boolean,
        default: false
      },
      fraudDataReportEnabled: {
        type: Boolean,
        default: false
      },
      showFeaturedSubmission: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState([
        'clearSelectedSubmissions',
        'removeSelectedSubmission'
      ])
    },
    data() {
      return {
        searchCriteria: null as SearchCriteria | null,
        searchCriteriaSelectedDataValues: [] as SearchCriteriaSelectedDataValue[],
        hasFilterParams: false as boolean,
        listViewFields: [] as Field[],
        listViewSelectedFields: [] as ListViewSelectedField[],
        listViewSelectedSubmissions: [] as Item[],
        isListView: false as boolean,
        selectedSubmissionIds: [] as number[],
        criterionToRemove: null as CriterionSelector | null
      };
    },
    watch: {
      clearSelectedSubmissions(): void {
        this.clearSelectedItems();
      },
      removeSelectedSubmission(newId: number, oldId: number): void {
        this.removeSelectedItem(newId);
      }
    },
    methods: {
      onSearchCriteriaChange(searchCriteria: SearchCriteria): void {
        this.searchCriteria = searchCriteria;

        let filterCriteria: SearchCriteriaSelectedDataValue[] = [];
        if (this.searchCriteria.criteria) {
          for (let criterion of this.searchCriteria.criteria) {
            if (criterion.definition) {
              let filterCriterionSelectedValue = new SearchCriteriaSelectedDataValue();
              filterCriterionSelectedValue.definition = criterion.definition.name;
              filterCriterionSelectedValue.selectedDataValues = criterion.selectedDataValues;
              filterCriteria.push(filterCriterionSelectedValue);
            }
          }
        }
        this.searchCriteriaSelectedDataValues = filterCriteria;

        this.clearSelectedItems();
      },
      onListViewFieldsChanged(fields: Field[]): void {
        this.listViewFields = fields;

        let listViewSelectedFields: ListViewSelectedField[] = [];
        for (let field of this.listViewFields) {
          let listViewSelectedField = new ListViewSelectedField();
          listViewSelectedField.entityType = field.entityType;
          listViewSelectedField.entityId = field.entityId;
          listViewSelectedField.priority = field.priority;
          listViewSelectedField.active = field.active;
          listViewSelectedFields.push(listViewSelectedField);
        }
        this.listViewSelectedFields = listViewSelectedFields;
        this.updateListViewStatus(SubmissionsView.list);
      },
      clearSelectedItems(): void {
        this.setSelectedSubmissionIds([]);
      },
      removeSelectedItem(id: number): void {
        const filteredSubmissionsIds: number[] = this.selectedSubmissionIds.filter((selectedId: number) => selectedId !== id);
        this.setSelectedSubmissionIds(filteredSubmissionsIds);
      },
      setSelectedSubmissionIds(selectedSubmissionIds: number[]): void {
        this.selectedSubmissionIds = selectedSubmissionIds;
      },
      setHasFilterParams(hasFilterParams: boolean): void {
        this.hasFilterParams = hasFilterParams;
      },
      convertItemToIdsAndSet(submissions: (Item | ItemContent)[]) {
        let selectedSubmissionIds: number[] = [];
        submissions.forEach((item: Item | ItemContent) => {
          if (item && item.id) {
            selectedSubmissionIds.push(item.id);
          }
        });

        this.setSelectedSubmissionIds(selectedSubmissionIds);
      },
      onThumbnailViewSubmissionsSelected(selectedSubmissionIds: number[]): void {
        this.setSelectedSubmissionIds(selectedSubmissionIds);
      },
      onListViewSubmissionsSelected(submissions: Item[]): void {
        this.listViewSelectedSubmissions = submissions;
        this.convertItemToIdsAndSet(submissions);
      },
      removeSelectedCriterion(criterionSelector: CriterionSelector | null): void {
        if (criterionSelector === null && this.criterionToRemove === null) {
          this.criterionToRemove = new CriterionSelector(new SearchDefinition(), new SearchData());
          setTimeout(() => {
            this.criterionToRemove = null;
          }, 200);
        } else {
          this.criterionToRemove = criterionSelector;
        }
      },
      listeningButtons(): void {
        const shareModal: HTMLElement | null = document.querySelector("#share-modal");
        if (shareModal) {
          generateShareHTMLService.listeningButtons(shareModal);
        }
      },
      initShareContentIdsFromBackend(): void {
        // Check if shared contents from backend already
        BackendIntegrationService.fetchShareContents()
          .then((item: ShareItem) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`Get shared items: '${item.contents?.length}'`);
            }
            if (item && item.contents) {
              this.convertItemToIdsAndSet(item.contents);
            }
          });
      },
      updateListViewStatus(submissionView: SubmissionsView) {
        this.isListView = submissionView === SubmissionsView.list;
      }
    },
    mounted() {
      this.initShareContentIdsFromBackend();
      this.listeningButtons();
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
