import { JsonObject, JsonProperty } from "json2typescript";
import { AttributeEntityValue } from "@/main/webapp/vue/model/api/AttributeEntity";

@JsonObject('DateTimeIso8601')
export class DateTimeIso8601 implements AttributeEntityValue {

  @JsonProperty('value', String, true)
  value?: string = undefined;

}
