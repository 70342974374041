
import Vue from 'vue';
import messages from './messages.json';
import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

import ProjectSubmissions from "@/main/webapp/vue/components/project/content/submissions/index.vue";
import SubmissionsFieldSelector
  from "@/main/webapp/vue/components/project/content/submissions-field-selector/index.vue";
import ListView, { FieldDataType } from "@/main/webapp/vue/views/submissions-view/list-view/index.vue";

import { SubmissionQueryCriteria } from "@/main/webapp/vue/model/SubmissionQueryCriteria";
import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";

export default Vue.extend({
    data() {
      return {
        fieldSelectorAdded: false as boolean
      };
    },
    methods: {
      injectToTableRow(params: SubmissionQueryCriteria): void {
        const tableRowContent: HTMLElement = new ProjectSubmissions({
          propsData: {
            submissionQueries: params
          },
          i18n: this.$i18n,
          store: this.$store
        }).$mount().$el as HTMLElement;

        const emptyTd: string = '<td colspan="1"></td>';
        const tableRow: Element | null = document.querySelector(`#project-submission-contents-${params.pid}-${params.chid}-${params.shid}`);
        if (tableRow) {
          tableRow.classList.add('child-row');
          tableRow.innerHTML = (emptyTd);
          tableRow.appendChild(tableRowContent);
        }
      },
      prepareParams(rawParams: DOMStringMap): SubmissionQueryCriteria {
        const keys: (keyof SubmissionQueryCriteria)[] = Object.keys(rawParams) as (keyof SubmissionQueryCriteria)[];
        const filteredObj: SubmissionQueryCriteria = {};

        keys.forEach((key: (keyof SubmissionQueryCriteria)) => {
          if (rawParams[key] && rawParams[key].length > 0) {
            // @ts-ignore
            filteredObj[key] = rawParams[key];
          }
        });

        return filteredObj;
      },
      addButtonListener(): void {
        const button: HTMLButtonElement | null = this.$refs['project-content-bridge'] as HTMLButtonElement | null;
        if (button) {
          button.addEventListener('click', () => {
            const { addfieldselector } = button.dataset;
            if (addfieldselector === 'true' && !this.fieldSelectorAdded) {
             this.addFieldSelector();
             return;
            }

            const params: SubmissionQueryCriteria = this.prepareParams(button.dataset);
            this.injectToTableRow(params);
          });
        }
      },
      getProjectSubmissionFields(): void {
        let fields: Field[] = new ListView({
          i18n: this.$i18n
        }).$mount().$data.fields;

        const fromStoreDataFields: Field[] = this.$store.state.projectSubmissionFields;
        const inactiveFieldKeys: FieldDataType[] = [FieldDataType.CHAIN, FieldDataType.SHOP, FieldDataType.PROJECT];

        if (fromStoreDataFields.length === 0) { // init first
          inactiveFieldKeys.forEach((key: FieldDataType) => {
            const field: Field | undefined = fields.find((f: Field) => f.key === key);
            if (field) {
              field.active = false;
            }
          });

          this.updateFieldsToStore(fields);
        }
      },
      updateFieldsToStore(fields: Field[]): void {
        this.$store.dispatch('updateProjectSubmissionFields', fields);
      },
      addFieldSelector(): void {
        this.getProjectSubmissionFields();
        const fieldSelector: HTMLElement = new SubmissionsFieldSelector({ // translation lost so pass it from here
          propsData: {
            title: this.$t('text.select-fields')
          },
          store: this.$store
        }).$mount().$el as HTMLElement;

        const tableCell: HTMLTableCellElement = document.querySelector('#projects-shop-table thead tr:nth-child(2) td') as HTMLTableCellElement;
        if (tableCell) {
          tableCell.appendChild(fieldSelector);
          this.fieldSelectorAdded = true;
        }
      }
    },
    mounted(): void {
      this.addButtonListener();
    },
    i18n: {
      messages: messages,
      sharedMessages: sharedMessages
    }
  });
