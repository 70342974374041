
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';
  import { mapState } from "vuex";

  import activitySideBar
    from "@/main/webapp/vue/components/learning/learning-home/course-management/course-activities/activity-side-bar/index.vue";
  import activityList
    from "@/main/webapp/vue/components/learning/learning-home/course-management/course-activities/activity-list/index.vue";
  import propertiesSideBar from "@/main/webapp/vue/components/learning/widget/component/PropertiesSideBar.vue";
  import widgetComponent from "@/main/webapp/vue/components/learning/widget/component/WidgetComponent.vue";
  import draggableContainer from "@/main/webapp/vue/components/ui/draggable/container/index.vue";
  import widgetSideBar, { WidgetPosition } from "@/main/webapp/vue/components/learning/widget/component/WidgetSideBar.vue";
  import courseLogo, { CourseLogoType } from "@/main/webapp/vue/components/learning/ui/content/course-logo/index.vue";
  import organizationFooter from "@/main/webapp/vue/components/ui/footer/index.vue";
  import Confirmation from "@/main/webapp/vue/components/ui/modal/confirmation/index.vue";
  import notification from "@/main/webapp/vue/notification";

  import WidgetUtil from "@/main/webapp/vue/components/learning/util/widgetUtil";

  import WidgetModel, { DraggableSection } from "@/main/webapp/vue/model/learning/WidgetModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
  import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { ContentUnit } from "@/main/webapp/vue/model/api/learning/ContentUnit";
  import { OrganizationData } from "@/main/webapp/vue/model/api/learning/OrganizationData";
  import learningActivityStatusSessionUtil from "@/main/webapp/vue/util/learningActivityStatusSessionUtil";
  import Znapson, { DeserializationError } from "@znapio/znapson";

  function findParentActivity(previousRoot: ActivityModel | undefined, root: ActivityModel,
                              find: ActivityModel): ActivityModel | undefined {
                                if (root.id === find.id) {
                                  return previousRoot;
                                }
                                return root.subactivities
                                  .map((value) => findParentActivity(root, value, find))
                                  .find((value) => value !== undefined);
                              }

  class EditingWidgetTracker {
    id: string | number;
    edited: boolean;

    constructor(id: string | number, edited: boolean = false) {
      this.id = id;
      this.edited = edited;
    }
  }

  export default Vue.extend({
    components: {
      activitySideBar,
      activityList,
      propertiesSideBar,
      widgetSideBar,
      widgetComponent,
      draggableContainer,
      courseLogo,
      organizationFooter
    },
    props: {
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      }
    },
    computed: {
      ...mapState([
        'courseDefaultStyles',
        'userDetails'
      ]),
      headerWidgets(): WidgetModel[] {
        return this.widgetsForSection(DraggableSection.HEADER);
      },
      bodyWidgets(): WidgetModel[] {
        return this.widgetsForSection(DraggableSection.BODY);
      },
      footerWidgets(): WidgetModel[] {
        return this.widgetsForSection(DraggableSection.FOOTER);
      }
    },
    data() {
      return {
        editingWidgetTracker: null as null | EditingWidgetTracker,
        editingWidget: undefined as WidgetModel | undefined,
        mainActivity: undefined as ActivityModel | undefined,
        activeActivity: undefined as ActivityModel | undefined,
        originalActivity: undefined as ActivityModel | undefined,
        logo: null as null, // TODO fetch logo
        courseLogo: {
          type: CourseLogoType.LOGO as CourseLogoType
        },
        lastWidgetPosition: null as WidgetPosition | null,
        widgetFromSidebar: false as Boolean, // Mainly for 2 layout column widget to see if new widget or old widget
        selectingWidgetCompatibleSection: null as DraggableSection | null,
        section: {
          all: DraggableSection.ALL as DraggableSection,
          header: DraggableSection.HEADER as DraggableSection,
          body: DraggableSection.BODY as DraggableSection,
          footer: DraggableSection.FOOTER as DraggableSection
        },
        thresholdOfScrollY: 100 as number, // When scroll down, navigation bar fixed on top in snapshop.js
        propertiesBar: {
          footerHeight: 45 as number, // fixed value
          heightFromBottom: '0%' as string, // set when created()
          top: 'auto' as string // top when position fixed
        },
        organizationData: undefined as OrganizationData | undefined
      };
    },
    watch: {
      editingWidget(newWidget: WidgetModel, oldWidget: WidgetModel): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('course-activities - this.editingWidget changed');
        }
        if (oldWidget !== undefined) {
          this.saveEdited(oldWidget, false);
        }
      }
    },
    methods: {
      confirmUnlinkWidget(widget: WidgetModel): void {
        let confirmation: Vue = new Confirmation({
          propsData: {
            title: this.$t('text.unlink.message.text'),
            okText: this.$t('text.unlink.message.button.save.this'),
            cancelText: this.$t('text.unlink.message.button.save.all')
          }
        }).$mount();

        confirmation.$on('user-confirm', (confirm: boolean) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log("User confirm", confirm);
          }
          if (confirm) {
            this.onUnlink(widget);
          } else {
            this.saveEdited(widget, false);
          }
        });
      },
      checkClickingOutsideOfEditingWidget(e: any): void {
        this.editingWidget = undefined;
      },
      checkSectionCompatibilityForClass(section: DraggableSection): string {
        if (this.selectingWidgetCompatibleSection === null) {
          return '';
        }

        if (this.selectingWidgetCompatibleSection === this.section.all) {
          return 'section-compatible';
        }

        return this.selectingWidgetCompatibleSection === section ? 'section-compatible' : 'section-not-compatible';
      },
      saveWidgetToBackend(widget: WidgetModel, index: number, parent?: WidgetModel): void {
        if (this.activeActivity) {
          const course: Course = Course.from(this.course);
          const contentUnit: ContentUnit = ContentUnit.from(widget, index);
          const activity: Activity = Activity.from(this.activeActivity);
          LearningIntegrationService.createCourseActivityContentUnit(course, activity, contentUnit, parent ? parent.id : undefined)
            .then((persistedContentUnit: ContentUnit) => {
              widget.updateId(persistedContentUnit.id as number);
              this.updateBackupActivity();
              if (process.env.NODE_ENV !== 'production') {
                console.log(`Persisted widget ${widget.id}`);
              }
            }).catch((error: BackendError) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`Create a course activity content unit failed: ${error}`);
              }
              notification.fail(this.$t('text.error.update-widget'));
            });
        } else {
          notification.fail(this.$t('text.error.update-widget-with-no-activity'));
        }
      },
      onWidgetCreated(widget: WidgetModel, index: number, parent?: WidgetModel, section?: DraggableSection): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Create widget on section ${section} and index: ${index}`);
        }

        if (section === undefined) {
          if (parent !== undefined) {
            section = parent.section;
          } else {
            section = widget.section;
          }
        }

        // Check last position
        let targetSection: HTMLDivElement = this.$refs.sectionHeader as HTMLDivElement;
        if (section === DraggableSection.BODY) {
          targetSection = this.$refs.sectionBody as HTMLDivElement;
        } else if (section === DraggableSection.FOOTER) {
          targetSection = this.$refs.sectionFooter as HTMLDivElement;
        }

        setTimeout(() => {
          let widgetInSection: boolean = WidgetUtil.checkWidgetPosition(targetSection, this.lastWidgetPosition);
          if (widgetInSection) {
            if (widget.section === DraggableSection.ALL && section !== undefined) {
              widget.section = section;
            }

            this.editingWidget = widget;
            this.saveWidgetToBackend(widget, index, parent);
          } else {
            this.deleteWidget(widget);
            if (process.env.NODE_ENV !== 'production') {
              console.log("Widget not in section");
            }
          }
        }, 0); // In order to have the latest end position

        this.toggleNavigationBar(true); // Widget that is already in list
      },
      toggleNavigationBar(show: boolean): void {
        let navigationbar: HTMLElement | null = document.querySelector("#navigation");
        if (navigationbar) {
          if (!show) {
            navigationbar.classList.add("widget-selected");
          } else {
            navigationbar.classList.remove("widget-selected");
          }
        }

        window.scrollTo(0, window.scrollY - 5); // Trigger snapshop.js initNavigation() scroll
      },
      onWidgetSelected(widget: WidgetModel): void {
        this.selectingWidgetCompatibleSection = widget.section;
        this.toggleNavigationBar(false);
      },
      onWidgetSelectedInSideBar(widget: WidgetModel) {
        this.onWidgetSelected(widget);
        this.widgetFromSidebar = true;
      },
      onWidgetEndedInSideBar(widgetPosition: WidgetPosition): void {
        this.lastWidgetPosition = widgetPosition;
        this.selectingWidgetCompatibleSection = null;
        this.widgetFromSidebar = false;
      },
      onWidgetEnded(widget: WidgetModel, index: number) {
        if (widget) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Widget ended: ${widget.id} ${index}`);
          }
          this.selectingWidgetCompatibleSection = null;
          if (this.activeActivity) {
            const course: Course = Course.from(this.course);
            const activity: Activity = Activity.from(this.activeActivity);
            const contentUnit = new ContentUnit();
            contentUnit.id = widget.id as number;
            contentUnit.sequence = index;
            LearningIntegrationService.updateCourseActivityContentUnit(course, activity, contentUnit)
              .catch((error: BackendError) => {
                notification.fail(this.$t('text.error.saving-widget-details'));
              });
          }
        }
        this.toggleNavigationBar(true); // Widget that is already in list
      },
      onEdit(widget: WidgetModel): void {
        if (this.editingWidget !== undefined) {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Widget being edited changed, saving previous one');
          }
          this.saveEdited(this.editingWidget, true);
        }
        this.editingWidget = widget;
      },
      deleteWidget(widget: WidgetModel): void {
        if (this.activeActivity) {
          this.activeActivity.widgets = (this.activeActivity.widgets || []).filter((e) => e.id !== widget.id);
          this.updateBackupActivity();
        }

        if (this.editingWidget && this.editingWidget.id === widget.id) {
          this.onStopEditing();
        }
      },
      deleteWidgetInBackend(widget: WidgetModel, nestedWidgetCallback?: Function): void {
        const contentUnit: ContentUnit = ContentUnit.from(widget, 0);
        if (this.activeActivity) {
          const course: Course = Course.from(this.course);
          const activity: Activity = Activity.from(this.activeActivity);
          LearningIntegrationService.deleteCourseActivityContentUnit(course, activity, contentUnit)
            .then(() => {
              if (nestedWidgetCallback) {
                nestedWidgetCallback(widget);
              }

              this.deleteWidget(widget);
            }).catch((error: BackendError) => {
              notification.fail(this.$t('text.error.delete-widget'));
            });
        }
      },
      onDelete(widget: WidgetModel, nestedWidgetCallback?: Function): void {
        if (this.activeActivity) {
          let title = this.$t('text.confirmation.delete-widget');
          let confirmation: Vue = new Confirmation({
            propsData: {
              title: title
            }
          }).$mount();

          confirmation.$on('user-confirm', (confirm: boolean) => {
            if (confirm) {
              this.deleteWidgetInBackend(widget, nestedWidgetCallback);
            }
          });
        } else {
          notification.fail(this.$t('text.error.delete-widget-with-no-activity'));
        }
      },
      onEdited(widget: WidgetModel, continueEditing: boolean, enforce: boolean = false): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Widget edited: '${widget.id}', continueEditing: '${continueEditing}', enforce: '${enforce}'`);
        }

        if (this.activeActivity) {
          this.editingWidgetTracker = new EditingWidgetTracker(widget.id, true);

          if (enforce || !continueEditing) {
            this.saveEdited(widget, false);
          }
        }
      },
      saveEdited(widget: WidgetModel, continueEditing: boolean, callback: Function | null = null): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('course-activities - saveEdited()');
        }

        if (this.activeActivity && this.editingWidgetTracker && this.editingWidgetTracker.id === widget.id) {
          const course: Course = Course.from(this.course);
          const activity: Activity = Activity.from(this.activeActivity);
          const contentUnit: ContentUnit = ContentUnit.from(widget);

          if (process.env.NODE_ENV !== 'production') {
            console.log(`Saving edited widget: '${widget.id}', continueEditing: '${continueEditing}'`);
          }
          LearningIntegrationService.updateCourseActivityContentUnit(course, activity, contentUnit)
            .then(() => {
              this.updateBackupActivity();
            }).catch((error: BackendError) => {
              notification.fail(this.$t('text.error.saving-widget-details'));
            }).finally(() => {
              if (!continueEditing) {
                this.onStopEditing();
              }

              if (callback) {
                callback();
              }
            });
        } else { // if not matched (i.e. editing but not actually edited)
          if (!continueEditing) {
            this.onStopEditing();
          }

          if (callback) {
            callback();
          }
        }
      },
      onStopEditing(clearTracker: boolean = true): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('course-activities - onStopEditing()');
        }

        if (this.editingWidget && this.editingWidgetTracker) {
          this.editingWidget = undefined;
        }

        if (clearTracker) {
          this.editingWidgetTracker = null;
        }
      },
      findBackupWidget(widgets: WidgetModel[], targetWidget: WidgetModel): Promise<WidgetModel> {
        return new Promise((resolve, reject) => {
          widgets.forEach((widget: WidgetModel) => {
            if (widget.id === targetWidget.id) {
              return resolve(widget);
            }

            // Only for the widget having nestedContents
            if ((widget as any).nestedContents && (widget as any).nestedContents.length > 0) {
              return this.findBackupWidget((widget as any).nestedContents, targetWidget).then((backupWidget: WidgetModel) => {
                resolve(backupWidget);
              });
            }
          });
        });
      },
      onCancel(widget: WidgetModel) {
        this.onStopEditing(); // cancel the update
        if (this.originalActivity) {
          this.findBackupWidget(this.originalActivity.widgets, widget).then((backupWidget: WidgetModel) => {
            widget.data = JSON.parse(JSON.stringify(backupWidget.data));
            widget.properties = JSON.parse(JSON.stringify(backupWidget.properties));
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Cancel widget failed", e);
            }
          });
        }
      },
      activateActivity(activityModel: ActivityModel, fetchDetails: boolean = false) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('course-activities - activateActivity()');
        }

        if (this.editingWidget) {
          this.saveEdited(this.editingWidget, false);
        }

        const course: Course = Course.from(this.course);
        let activity = Activity.from(activityModel);
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Activate activity: ${activity.name}`);
        }
        if (fetchDetails) {
          LearningIntegrationService.fetchCourseActivityDetails(course, activity)
            .then((fetchedActivity: Activity) => {
              activityModel.from(ActivityModel.from(fetchedActivity));
              this.$emit('activity-activated', fetchedActivity);
              this.updateBackupActivity();
            }).catch((error: BackendError) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log("Loading course details failed", error);
              }
              notification.fail(this.$t('text.error.loading-activity-details'));
            }).catch((error: DeserializationError) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('Deserializing activity contents failed', error);
              }
              notification.fail(this.$t('text.error.loading-activity-details'));
            }).finally(() => {
              this.activeActivity = activityModel; // fallback, won't show subactivities, details, etc.
            });
        } else {
          this.activeActivity = activityModel;
        }

        learningActivityStatusSessionUtil.markVisited(activityModel.id); // For activity list status
      },
      editActivity(activityModel: ActivityModel): void {
        const course: Course = Course.from(this.course);
        let activity = Activity.from(activityModel);
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Edit activity: ${activity.name}`);
        }
        LearningIntegrationService.updateCourseActivity(course, activity)
          .then((fetchedActivity: Activity) => {
            activityModel.from(ActivityModel.from(fetchedActivity));
            this.$emit('activity-activated', fetchedActivity);
            this.updateBackupActivity();
          }).catch((error: BackendError) => {
            if (error.exception === 'subactivity-url-already-in-use') {
              notification.fail(this.$t('error.subactivity-url-already-in-use'));
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log('Update activity failed', error);
              }
              notification.fail(this.$t('text.error.update-activity'));
            }
          }).catch((error: DeserializationError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Deserializing activity contents failed', error);
            }
            notification.fail(this.$t('text.error.loading-course-details'));
          });
      },
      deleteActivity(activityModel: ActivityModel): void {
        const course: Course = Course.from(this.course);
        let activity = Activity.from(activityModel);
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Delete activity: ${activity.name}`);
        }
        LearningIntegrationService.deleteCourseActivity(course, activity)
          .then((fetchedActivity: Activity) => {
            const parent = this.mainActivity && findParentActivity(undefined, this.mainActivity, activityModel);
            if (parent) {
              parent.subactivities = parent.subactivities.filter((a) => a.id !== activity.id);
              this.activeActivity = parent;
            }
            this.updateBackupActivity();
          }).catch((error: BackendError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Delete activity failed", error);
            }
            notification.fail(this.$t('text.error.delete-activity'));
          });
      },
      updateBackupActivity(): void {
        this.originalActivity = JSON.parse(JSON.stringify(this.activeActivity)); // Not to copy the activeActivity itself
      },
      handleScroll(): void {
        // Properties bar position
        const activateFixedPosition: boolean = (window.scrollY >= this.thresholdOfScrollY);
        this.propertiesBar.top = activateFixedPosition ? `${(this.propertiesBar.footerHeight - 2)}px` : 'auto';

        // Properties bar height
        const scrollableRange: number = (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        const maxHeightFromBottom: number = 125;
        const heightWhenPositionFixed: number = 50;
        const heightWhenReachBottom: number = 20;
        let heightFromBottom = "0%";

        if (activateFixedPosition) {
          heightFromBottom = `${heightWhenPositionFixed}px`;
          if ((scrollableRange - window.scrollY) <= heightWhenReachBottom) {
            heightFromBottom = `${maxHeightFromBottom}px`;
          }
        }

        this.propertiesBar.heightFromBottom = heightFromBottom;
      },
      widgetsForSection(section: DraggableSection): WidgetModel[] {
        if (this.activeActivity !== undefined) {
          return this.activeActivity.widgets
            .filter((widget) => widget.section === section);
        }
        return [];
      },
      updateSectionWidgets(widgets: WidgetModel[], section: DraggableSection): void {
        if (this.activeActivity !== undefined) {
          widgets.forEach(widget => {
            if (widget.section === this.section.all) {
              widget.section = section;
            }
          });

          this.activeActivity.widgets = widgets;
        }
      },
      onUnlink(widget: WidgetModel): void {
        if (this.activeActivity) {
          const contentUnit: ContentUnit = ContentUnit.from(widget);
          const course: Course = Course.from(this.course);
          const activity: Activity = Activity.from(this.activeActivity);

          LearningIntegrationService.unlinkContent(course, activity, contentUnit)
            .then((unlinkedContent: ContentUnit) => {
              notification.info(this.$t('text.success.unlink-widget'));
              let unlinkedWidget: WidgetModel = Znapson.deserialize(unlinkedContent, WidgetModel);
              widget.updateWithModel(unlinkedWidget);
            }).catch((error: BackendError) => {
              notification.fail(this.$t('text.error.unlink-widget'));
            });
        }
      }
    },
    mounted() {
      let mainActivityLink = NavigationLinks.getLinkFromNavigation(Course.MAIN_ACTIVITY_LINK, this.course.nav);
      if (this.course && this.course.mainActivity) {
        LearningIntegrationService.fetchCourseActivityDetails(Course.from(this.course), this.course.mainActivity, mainActivityLink)
          .then((activity: Activity) => {
            this.mainActivity = ActivityModel.from(activity);
            this.activeActivity = this.mainActivity;
            this.organizationData = activity.organizationData;
            this.updateBackupActivity();
            this.$emit('activity-activated', this.mainActivity);
          }).catch((error: BackendError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Loading activity failed", error);
            }
            notification.fail(this.$t('text.error.loading-courses'));
          }).catch((error: DeserializationError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Deserializing activity contents failed', error);
            }
            notification.fail(this.$t('text.error.loading-courses'));
          });
      }
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    i18n: {
      messages: messages
    }
  });
