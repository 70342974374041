import { WidgetPosition } from "@/main/webapp/vue/components/learning/widget/component/WidgetSideBar.vue";
import { CourseBrandingStyle } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";

export default class WidgetUtil {
  static checkWidgetPosition(targetSection: Element, lastWidgetPosition: WidgetPosition | null): boolean {
    const sectionBoundary = (targetSection as HTMLDivElement).getBoundingClientRect();
    const widgetX = lastWidgetPosition?.x ?? 0;
    const widgetY = (lastWidgetPosition?.y ?? 0) + window.pageYOffset; // Adjust for scrolling

    if (
      widgetX >= sectionBoundary.left &&
      widgetX <= sectionBoundary.right &&
      widgetY >= sectionBoundary.top + window.pageYOffset && // Ensure y-coordinates are aligned
      widgetY <= sectionBoundary.bottom + window.pageYOffset
    ) {
      return true;
    }

    return false;
  }

  static setDefaultStyles(properties: any, defaultStyles: CourseBrandingStyle[]): any {
    for (let [name, property] of Object.entries(properties)) {
      defaultStyles.forEach((defaultStyle: CourseBrandingStyle) => {
        if (defaultStyle.updated && (property as any).constructor.name === defaultStyle.data.constructor.name) {

          for (let [styleKey, styleValue] of Object.entries(defaultStyle.data)) {
            if (styleValue && styleKey in properties[name]) {
              properties[name][styleKey] = styleValue;
            }
          }
        }
      });
    }

    return properties;
  }
}
